
$theme-colors: (
  'info': blue,
  'danger': #F12A13,
  'primary':tomato,
  'warning': yellow,
  'dark-red': #AD1E0E,
  'green-gyn': #00F0C1,
  'bege': #EFDEC4,
  'grey': #212529,
  'red-fire': #CF4333,
  'light-grey': #d7ddde,
  'light-red': #A54E44,
);

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/futura-pt-bold.eot');
  src: url('../fonts/futura-pt-bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/futura-pt-bold.woff2') format('woff2'),
      url('../fonts/futura-pt-bold.woff') format('woff'),
      url('../fonts/futura-pt-bold.ttf')  format('truetype'),
      url('../fonts/futura-pt-bold.svg#Futura PT') format('svg');
  font-display: swap
}

  @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-VariableFontwdthwght.eot');
    src: url('../fonts/OpenSans-VariableFontwdthwght.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-VariableFontwdthwght.woff2') format('woff2'),
        url('../fonts/OpenSans-VariableFontwdthwght.woff') format('woff'),
        url('../fonts/OpenSans-VariableFontwdthwght.ttf')  format('truetype'),
        url('../fonts/OpenSans-VariableFontwdthwght.svg#Open Sans') format('svg');
  font-display: swap
}

p{
  font-family: 'Open Sans';
}
h1,h2,h3,h4,h5,h6 {
  font-family: 'Futura PT';
}

.accordion-header button{
    background-color: black;
    color: azure;
}

// .navbar {
//   display: none !important;
// }

.bg-home {
  background-image: url('../Images/bg-home-gyntubos-insdustriais.avif');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100%;
  height: auto;
}

.desk {
  display: inherit;
  @media (max-width:700px) {
    display: none;
  }
}

.mobile {
  display: none;
  @media (max-width:700px) {
    display: inherit;
  }
}

.w-logo{
  width: 10%;
  @media (max-width:991px) {
    width: 15%;
  }
  @media (max-width:768px) {
    width: 25%;
  }
}

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';