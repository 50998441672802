.items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .item {
    width: 100%;
    max-width: 200px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .item-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .item-content img {
    width: 100%;
    max-width: 150px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .page-info {
    text-align: center;
    margin: 10px 0;
  }
  